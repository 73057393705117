import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CellEditing } from '@unique/component-library';

export interface DueDiligenceState {
  editingCell: CellEditing | null;
}

const initialState: DueDiligenceState = {
  editingCell: null,
};

export const dueDiligenceSlice = createSlice({
  name: 'dueDiligence',
  initialState,
  reducers: {
    updateEditingCell: (state, action: PayloadAction<CellEditing>) => {
      state.editingCell = action.payload;
    },
  },
});

export const { updateEditingCell } = dueDiligenceSlice.actions;

export default dueDiligenceSlice.reducer;
